<template>
  <div class="tec_body_all">
    <div
      style="
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      "
    >
      <div class="tec_btn">
        <div class="tec_title">提现订单管理</div>
      </div>
      <div style="width: 90%; margin-top: 10px">
        <div
          style="
            display: flex;
            align-items: center;
            margin-top: 20px;
            justify-content: space-between;
            margin-bottom: 10px;
          "
        >
          <el-select
            style="width: 150px"
            v-model="value"
            class="m-2"
            placeholder="全部"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <div class="tec_search">
            <el-button type="primary" @click.prevent="encyclopediasInfo()">刷新</el-button>
            <el-input
              style="width: 220px"
              v-model="input"
              placeholder="搜索关键字"
              clearable
            />
            <el-button type="primary" @click.prevent="Search()">搜索</el-button>
          </div>
        </div>

        <el-table :data="data.tecInfo1" height="451px" style="width: 100%">
          <el-table-column prop="id" label="提现单号" />
          <el-table-column prop="name" label="律师姓名" />
          <el-table-column prop="phoneNumber" label="手机号" />
          <el-table-column prop="bankId" label="银行卡号" />
          <el-table-column prop="bankName" label="卡户姓名" />
          <el-table-column prop="bankLocal" label="开户行地址" />
          <el-table-column prop="money" label="提现金额" />
          <el-table-column prop="userMoney" label="剩余可提现金额" />
          <el-table-column prop="state" label="状态">
            <template v-slot="scope">
              <p v-if="scope.row.state == 1" style="color: #409eff">已通过</p>
              <p v-if="scope.row.state == 0" style="color: #f56c6c">未审核</p>
              <p v-if="scope.row.state == 2" style="color: #f56c6c">已拒绝</p>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="120px">
            <template #default="scope">
              <div style="display: flex">
                <el-button
                  type="primary"
                  size="small"
                  @click.prevent="editRow(scope.row)"
                >
                  审核
                </el-button>
                <el-button
                  type="danger"
                  size="small"
                  @click.prevent="deleteRow(scope.row)"
                  >删除</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="demo-pagination-block">
        <el-pagination
          v-model:currentPage="currentPage4"
          :page-sizes="[10, 20, 30, 40]"
          v-model:page-size="data.page_size"
          v-model:current-page="data.page"
          layout="total, sizes, prev, pager, next, jumper"
          :total="data.tecInfo.length"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, getCurrentInstance, onMounted, reactive } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  name: "tecarticle",
  setup() {
    const { proxy } = getCurrentInstance();
    const input = ref("");
    const currentPage4 = ref(4);
    const value = ref("");
    const data = reactive({
      a_type:1,
      page: 1,
      page_size: 10,
      tecInfo: [],
      tecInfo1: [],
    });
    const options = [
      {
        value: "全部",
        label: "全部",
      },
      {
        value: "点赞量",
        label: "点赞量",
      },
      {
        value: "收藏量",
        label: "收藏量",
      },
    ];
    onMounted(() => {
      encyclopediasInfo();
    });
    function getPageData() {
      let start = (data.page - 1) * data.page_size;
      let end = start + data.page_size;
      data.tecInfo1 = data.tecInfo.slice(start, end);
      // console.log(start)
      // console.log(end)
      // console.log(data.userinfo)
    }
    function handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      data.page_size = val;
      getPageData();
    }
    function handleCurrentChange(val) {
      data.page = val;
      console.log(val);
      getPageData();
    }
    function encyclopediasInfo() {
      proxy.$http
        .get("/bank/queryBankList",{
          page:data.page,
          limit:data.page_size
        })
        .then((res) => {
          console.log(res);
          data.tecInfo = res.data;
          getPageData(); //分页
          // console.log(data);
        });
    }
    function Search() {
      proxy.$http
        .get("/ArticleController/selectTecArticle", {
          Keyword: input.value,
        })
        .then((res) => {
          console.log(res);
          data.tecInfo = res.data;
          getPageData(); //分页
          if (res.code === 1) {
            ElMessage({
              type: "success",
              message: "搜索成功",
            });
          } else
            ElMessage({
              type: "error",
              message: "搜索失败",
            });
        });
    }
    function editRow(row) {
      console.log(row);
      ElMessageBox.confirm("此操作将通过该申请, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() =>{
        proxy.$http
          .post("/bank/updateWithdraw", {
            id: row.id,
            reason:"",
            checkTime:""
          })
          .then((res) => {
            console.log(res);
            if (res.code === 20000) {
              ElMessage({
                type: "success",
                message: "通过成功",
              });
              encyclopediasInfo();
            } else
              ElMessage({
                type: "error",
                message: "通过失败",
              });
          });
      })
    }
    function deleteRow(row) {
      console.log(row);
      ElMessageBox.confirm("此操作将永久删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          proxy.$http
            .get("/ArticleController/deleteArticle", {
              article_id: row.article_id,
            })
            .then((res) => {
              console.log(res);
              if (res.code === 1) {
                ElMessage({
                  type: "success",
                  message: "删除成功",
                });
                encyclopediasInfo();
              } else
                ElMessage({
                  type: "error",
                  message: "删除失败",
                });
            });
        })
        .catch(() => {
          ElMessage({
            type: "error",
            message: "撤销操作",
          });
        });
    }
    return {
      input,
      data,
      currentPage4,
      value,
      options,
      editRow,
      Search,
      encyclopediasInfo,
      deleteRow,
      handleSizeChange,
      handleCurrentChange,
    };
  },
};
</script>

<style>
.tec_body_all {
  background-color: white;
  padding: 20px 20px 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tec_title {
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 2px;
  margin: 20px 20px 10px 20px;
}
.tec_btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.tec_search {
  display: flex;
  justify-content: end;
}
.demo-pagination-block {
  margin-top: 10px;
}
/*隐藏滚动条*/
.el-table__body-wrapper {
  padding-right: 20px;
}
</style>
